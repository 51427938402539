import React, { useEffect, useState } from 'react'
import {
    Card, TextField, Breadcrumbs, Typography, FormControl, Button, Autocomplete,
    Backdrop,
    CircularProgress
} from '@mui/material'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { AiOutlineOrderedList } from 'react-icons/ai';
import Snackbar from '@mui/material/Snackbar';

const invityList = ['individuals','businesses'];

function Invite() {
    const navigate = useNavigate();
    const location = useLocation();
    const [dataList, setDataList] = React.useState([]);
    const [newInvite, setInvite] = useState({ name: '', description: '', email: '', role: '' });
    const [open, setOpen] = React.useState(false);
    //const [ setSelectInv] = useState('');
    //const [ setSelectRole ] = useState('');


    const [sopen, setSOpen] = React.useState(false);

    const handleClose = (event, reason
    ) => {
        if (reason === 'clickaway') {
            return;
        }

        setSOpen(false);
    };

    const fetchUsers = async () => {
        const listUser = await axios.get(`https://nuggettrap.com/api/list/role-list.php`)
        const nnData = listUser.data.result;
        setDataList(nnData)
        console.log(listUser.data.result);
    }
    const handalSubmit = async () => {
        if (newInvite.name === '') return alert('Please enter name.')
        if (newInvite.email === '') return alert('Please enter email.')
        if (newInvite.role === '') return alert('Please enter role.')
        //console.log(newInvite); return;
        setOpen(true);
        if (newInvite.id) {
            await axios.post(`${process.env.REACT_APP_API_URL}/invite/update`, newInvite)
            setSOpen(true);
        } else {
            await axios.post(`${process.env.REACT_APP_API_URL}/invite/add`, newInvite)
            setSOpen(true);
        }
       // setSelectInv('')
        setInvite({ name: '', description: '', email: '', role: '' })
       // setSelectRole('')
        setOpen(false);
    }

    useEffect(() => {
        fetchUsers()
    }, [])
    useEffect(() => {
        if (location.state) {
            setInvite(location.state)
        }
    }, [location])
    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>List</Typography>
                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Button variant="contained" color='primary'
                    onClick={() => { navigate('/dashboard/invite-list') }}
                >List
                    <AiOutlineOrderedList size={20} />
                </Button>
                <Card style={{ padding: '10px' }}>
                    <h4>Invite link</h4>
                    <form>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Name" size='small'
                                variant="outlined" value={newInvite.name}
                                onChange={(e) => { setInvite((prev) => ({ ...prev, name: e.target.value })) }}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Description" size='small'
                                variant="outlined" value={newInvite.description}
                                onChange={(e) => { setInvite((prev) => ({ ...prev, description: e.target.value })) }}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Email" size='small' type='email'
                                variant="outlined" value={newInvite.email}
                                onChange={(e) => { setInvite((prev) => ({ ...prev, email: e.target.value })) }}
                            />
                        </FormControl>
                        {/* <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Role" size='small'
                                variant="outlined" value={newInvite.role}
                                onChange={(e) => { setInvite((prev) => ({ ...prev, role: e.target.value })) }}
                            />
                        </FormControl> */}
                        
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <Autocomplete
                                size='small'
                                options={dataList}
                                onChange={(e, newValue) => {

                                     setInvite((prev) => ({ ...prev, role: newValue?.name }));
                                    console.log(newValue)
                                    
                                }}
                                getOptionLabel={(option) => option.name}
                                sx={{ width: 230 }}
                                renderInput={(params) => <TextField {...params} label="Role list" />}
                            />
                        </FormControl >
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <Autocomplete
                                size='small'
                                options={invityList}
                                onChange={ (e, newValue) => {
                                     setInvite((prev) => ({ ...prev, invite_type: newValue }));
                                }}
                                sx={{ width: 230 }}
                                renderInput={(params) => <TextField {...params} label="Select type" />}
                            />
                        </FormControl >
                        {newInvite.id ? (
                            <Button variant="contained" color='warning' style={{ width: '100%' }}
                                onClick={handalSubmit}>Update</Button>
                        ) : (
                            <Button variant="contained" style={{ width: '100%' }}
                                onClick={handalSubmit}>Submit</Button>
                        )}


                        {/* <div className='row'>
                            <div className='col-sm-12'>
                                <hr />
                                <BasicTable />
                            </div>
                        </div> */}
                        <Snackbar
                            open={sopen}
                            autoHideDuration={5000}
                            onClose={handleClose}
                            severity="success"
                            message="Invite successfully added!"
                        />
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={open}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </form>
                </Card>
            </div>
        </div>
    )
}

export default Invite