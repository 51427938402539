import React, { useState, useEffect } from 'react'
import { Breadcrumbs, Typography } from '@mui/material';
import { Card, Col, Row, Table, Button } from 'reactstrap'

import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { FaEye, FaTrash } from 'react-icons/fa';

function SubmitedPools() {
    const navigate = useNavigate();

    const [questionList, setquestionList] = useState([]);

    const fetchQus = async () => {
        const resaa = await axios.get(`${process.env.REACT_APP_API_URL}/pool/submit`);
        setquestionList(resaa.data.result)
        console.log(resaa.data.result)
    }
    const deleteF = async (data) => {
        if (window.confirm("Are you sure!") == true) {
            const resaa = await axios.post(`${process.env.REACT_APP_API_URL}/pool/submit-delete`, { id: data.id });
            fetchQus();
        }
    }
    useEffect(() => {
        fetchQus();
    }, [])
    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>List</Typography>
                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Card className='pt-5 pb-5 ps-5'>
                    <Row className='bg-white width-100'>
                        <Col md={12} className=''>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Sl</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Contact</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {questionList.length > 0 &&
                                        <>
                                            {questionList.map((list, i) => (
                                                <tr key={i}>
                                                    <td>{i + 1} </td>
                                                    <td>{list.fullname} </td>
                                                    <td>{list.email} </td>
                                                    <td>{list.contact} </td>
                                                    <td>
                                                    <Button size='sm' color='primary' onClick={() => { navigate('/dashboard/view-details', {state: list}) }} ><FaEye /> </Button>
                                                    <Button size='sm' color='danger' onClick={() => { deleteF(list) }} ><FaTrash /> </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Card>
            </div>
        </div>
    )
}

export default SubmitedPools