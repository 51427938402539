import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconButton, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import { AiFillPlusCircle, AiFillDelete } from 'react-icons/ai';
import { useEffect } from 'react';
import axios from 'axios'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function RoleTable(props) {
    const [tblForm, settblForm] = React.useState({ perc: '', coin: '', date_: '' });
    const [tblData, settblData] = React.useState([]);
    const [timepList, setTimePlist] = React.useState([]);

    const handalAdd = async () => {

        if (!tblForm.perc) return console.error('enter all fileds')
        if (tblForm.perc === '' || tblForm.date_ === '') {
            console.error('enter all fileds')
            return
        } else {

        }
        settblData(prevArray => [...prevArray, tblForm]);
        var formVal = tblForm;
        var mainRow = tblData;
        mainRow.push(formVal)
        console.log(mainRow)
        props.tableList(mainRow);

        settblForm({ perc: '', coin: '', date_: '' });

    }
    const handalDel = (delid) => {
        const newArray = tblData.filter(item => item.id !== delid);
        settblData(newArray);
    }

    useEffect(() => {
        if (props.formEvent === true) {
            settblData([]);
        }
    }, [props.formEvent])

    useEffect(() => {
        if (props.showIngTblEdit.length > 0) {
            settblData(props.showIngTblEdit)
        }
        console.log(props.showIngTblEdit)
    }, [props.showIngTblEdit])

    const gteAll = async () => {
        const resAdd = await axios.get(`https://nuggettrap.com/api/list/time-p.php`)
        setTimePlist(resAdd.data.result);
        console.log(resAdd.data.result)
    }
    useEffect(() => {
        gteAll();
    }, [])

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 400 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell width={'10%'}>Percentage</StyledTableCell>
                        {/* <StyledTableCell width={'10%'}>Coin</StyledTableCell> */}
                        <StyledTableCell width={'10%'}>Holding period (days)</StyledTableCell>
                        <StyledTableCell width={'10%'}>Action</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody className='add-row'>
                    <StyledTableRow >
                        <StyledTableCell component="td" scope="row">
                            <TextField label="Percentage" type='number' variant="standard" style={{ width: '90px' }}
                                value={tblForm?.perc} onChange={(e) => { settblForm((prev) => ({ ...prev, perc: e.target.value })) }}
                            />
                        </StyledTableCell>
                        {/* <StyledTableCell >
                            <TextField label="Coin" type='number' variant="standard" style={{ width: '90px' }} 
                            value={tblForm?.coin} onChange={(e)=>{ settblForm((prev)=>({...prev, coin: e.target.value })) }}/>
                        </StyledTableCell> */}
                        <StyledTableCell >
                            <Autocomplete
                                size='small'
                                options={timepList}
                                sx={{ width: 230 }}
                                // value={tblForm.date_}
                                getOptionLabel={(option) => option.text_data}
                                onChange={(e, v) => { settblForm((prev) => ({ ...prev, date_: v?.text_data })) }}
                                renderInput={(params) => <TextField {...params} label="Currency type" />}
                            />
                            {/* <TextField type='month' variant="standard" style={{ marginTop: '16px' }} 
                            value={tblForm?.date_} onChange={(e)=>{ settblForm((prev)=>({...prev, date_: e.target.value, id: Date.now() })) }}/> */}
                        </StyledTableCell>
                        <StyledTableCell align='right' >
                            <IconButton color='success' onClick={handalAdd}>
                                <AiFillPlusCircle />
                            </IconButton>
                        </StyledTableCell>
                    </StyledTableRow>
                    {tblData.length > 0 &&
                        <>
                            {tblData.map((list, i) => (
                                <StyledTableRow key={i}>
                                    <StyledTableCell component="th" scope="row">
                                        {list.perc}
                                    </StyledTableCell>
                                    {/* <StyledTableCell >{list.coin}</StyledTableCell> */}
                                    <StyledTableCell >{list.date_}</StyledTableCell>
                                    <StyledTableCell >
                                        <IconButton color='error' onClick={() => { handalDel(list.id) }}>
                                            <AiFillDelete />
                                        </IconButton>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}

//export default RoleTable