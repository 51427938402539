import { Card, Breadcrumbs, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import React, { useState } from 'react'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import RoleLIst from './RoleLIst'


function RolemasterList() {
    //const [roleData, setRoleData] = useState([]);

    const [submitData] = useState(false);

    const [open] = React.useState(false);

    
    // const handalSubmit = () => {
    //     if (!rolePersonal.name) return alert('enter role name');
    //     if (rolePersonal.tabledata.length === 0) return alert('enter conin details');

    //     setOpen(!open);
    //     console.log(rolePersonal)
    //     axios.post(`${process.env.REACT_APP_API_URL}/add-role`, rolePersonal)
    //         .then((res) => {
    //             setOpen(false);
    //             setPersolnal({ name: '', descriptino: '', offer: '', total_coin: '', tabledata: [] });
    //             //setRoleData([]);
    //             setsubmitData(true);
    //         })
    // }

    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>List</Typography>
                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Card style={{ padding: '10px' }}>
                    <h4 className='text-center'>Role list</h4>
                    <RoleLIst hitsubmit={submitData} dataAfterSubmit={[]}  />
                </Card>
            </div>


            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}

            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default RolemasterList