import React from 'react'

import imga1 from '../img/im1.png';
import imga2 from '../img/im4.png';
//import imga3 from '../img/img12.png';

import imga11 from '../img/img11.png';
import img12 from '../img/img12.png';
import img13 from '../img/img13.png';
import img14 from '../img/img14.png';

function ViewMore() {
    return (
        <div>
            <div className='bg-11 pt-5 pb-5'>
                <div className='container'>
                    <div className='row'>
                        
                        <div className='col-sm-2'></div>
                        <div className='col-sm-10'>
                            <div className='row pt-5 pb-5  cex-img'>
                                <div className='col-sm-6'>
                                    <img src={imga1} alt='' />
                                </div>
                                <div className='col-sm-6'>
                                    <img src={imga2} alt='' />
                                </div>
                                <div className='col-sm-6'>
                                    <img src={imga11} alt='' />
                                </div>
                                <div className='col-sm-6 '>
                                    <img src={img12} alt='' />
                                </div>
                                <div className='col-sm-6'>
                                    <img src={img13} alt='' />
                                </div>
                                <div className='col-sm-6'>
                                    <img src={img14} alt='' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewMore