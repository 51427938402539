import { Card, TextField, Breadcrumbs, Typography, FormControl, Button } from '@mui/material'
import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import RoleTable from './RoleTable'
import axios from 'axios'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import RoleLIst from './RoleLIst'
import Snackbar from '@mui/material/Snackbar';



function Rolemaster() {
    const [rolePersonal, setPersolnal] = useState({});
    const [roleData, setRoleData] = useState([]);

    const [submitData, setsubmitData] = useState();
    const [data, setData] = useState([]);
    const [CointableData, setcoinTblData] = useState([]);

    const [open, setOpen] = React.useState(false);
    const [sopen, setSOpen] = React.useState(false);

    const handleClose = (event, reason
    ) => {
        if (reason === 'clickaway') {
            return;
        }

        setSOpen(false);
    };

    const gettblData = (e) => {
        setRoleData(e);
        setPersolnal((prev) => ({ ...prev, tabledata: e }))
    }
    const getEditData = (e) => {
        setPersolnal(e)
        console.log(e.tabledata) // showing table data
        setcoinTblData(e.tabledata)
        setRoleData(e.tabledata);
    }
    //const newGet =(e)=>{ console.log(e)}
    const handalSubmit = () => {
        console.log(rolePersonal)
       // return;
        if (!rolePersonal.name) return alert('enter role name');
        if (rolePersonal.tabledata.length === 0) return alert('enter coin details');

        setOpen(!open);
        console.log(rolePersonal)
        var url = "";
        if (rolePersonal.id) {
            url = `${process.env.REACT_APP_API_URL}/edit-role`;
        } else {
            url = `${process.env.REACT_APP_API_URL}/add-role`;
        }
        setsubmitData(true);
        axios.post(url, rolePersonal)
            .then((res) => {
                setSOpen(true);
                setOpen(false);
                setPersolnal({ name: '', descriptino: '', offer: '', total_coin: '', tabledata: [] });
                setRoleData([]);
                setsubmitData(false);
                setData(res.data.result)
                console.log(res.data.result)
            })
    }

    

    useEffect(()=>{
        console.log(submitData,roleData)
    },[submitData,roleData])

    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>List</Typography>
                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Card style={{ padding: '10px' }}>
                    <h4>Add Category</h4>
                    <div className="row">
                        <div className='col-sm-6' >
                            <form>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Role name" size='small'
                                        variant="outlined" value={rolePersonal.name}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e) => { setPersolnal((prev) => ({ ...prev, name: e.target.value })) }}
                                    />
                                </FormControl>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Role description" size='small' InputLabelProps={{ shrink: true }}
                                        variant="outlined" value={rolePersonal.descriptino}
                                        onChange={(e) => { setPersolnal((prev) => ({ ...prev, descriptino: e.target.value })) }}
                                    />
                                </FormControl >
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Total no of coins" size='small' type='number'
                                        variant="outlined" value={rolePersonal.total_coin} InputLabelProps={{ shrink: true }}
                                        onChange={(e) => { setPersolnal((prev) => ({ ...prev, total_coin: e.target.value })) }}
                                    />
                                </FormControl >
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Offer details" size='small'
                                        variant="outlined" value={rolePersonal.offer} InputLabelProps={{ shrink: true }}
                                        onChange={(e) => { setPersolnal((prev) => ({ ...prev, offer: e.target.value })) }}
                                    />
                                </FormControl >

                            </form>
                        </div>
                        <div className='col-sm-6'>
                            
                            {/* <RoleTable tblData={gettblData} submitFlaf={submitData} roleData={roleData} /> */}
                            <RoleTable tableList={gettblData} formEvent={submitData} showIngTblEdit={CointableData}  />
                        </div>
                        <div className='col-sm-12 text-center' sx={{ m: 2, }} >
                            <br />
                            <br />
                            {rolePersonal.id ? (
                                <Button variant="contained" color='warning' onClick={handalSubmit}>Update Role</Button>
                            ) : (
                                <Button variant="contained" onClick={handalSubmit}>Add Role</Button>
                            )
                            }

                        </div>
                    </div>
                </Card>
            </div>
            <div className='text-inner'>
                <Card style={{ padding: '10px' }}>
                    <h4 className='text-center'>Role list</h4>
                    <RoleLIst hitsubmit={submitData} dataAfterSubmit={data} editRow={getEditData} />
                </Card>
            </div>
            <Snackbar
                open={sopen}
                autoHideDuration={5000}
                onClose={handleClose}
                severity="success"
                message="Role successfully added!"
            />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}

            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default Rolemaster