import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import axios from 'axios';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';



function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    //const [roleList, setRoleList] = React.useState([]);
    //const [user, setUser] = React.useState(null);

    const handael = async(delrow)=>{
        console.log(delrow)
        if (window.confirm('Are you sure!') === true) {
            axios.post(`${process.env.REACT_APP_API_URL}/delete-role`, delrow)
            .then((res) => {
                console.log(res)
                props.delrow(res)
            })
        }
    }
    const handelEdit =(data)=>{
        props.editRow(data)
    }
    // React.useEffect(() => {
    //     const userData = localStorage.getItem('user');
    //    // setUser(JSON.parse(userData));
    // }, [])

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.name}
                </TableCell>
                <TableCell align="right">{row.total_coin}</TableCell>
                <TableCell align="right">{row.descriptino}</TableCell>
                <TableCell align="right">{row.offer}</TableCell>
                <TableCell align="right">
                    <IconButton color='error' onClick={()=>{ handael(row) }}>
                        <AiFillDelete />
                    </IconButton> 
                    <IconButton color='error' onClick={()=>{ handelEdit(row) }}>
                        <AiFillEdit />
                    </IconButton>

                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                History
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Percentage</TableCell>
                                        <TableCell>Coin</TableCell>
                                        <TableCell align="right">Holding period (days)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.tabledata.map((historyRow) => (
                                        <TableRow key={historyRow.date}>
                                            <TableCell component="th" scope="row">
                                                {historyRow.perc}
                                            </TableCell>
                                            <TableCell>{historyRow.coin}</TableCell>
                                            <TableCell align="right">{historyRow.date_}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function RoleLIst(props) {
    const [dataList, setDataList] = React.useState([]);
    const okcheck = (e) =>{
        console.log(e.data.result)
        setDataList(e.data.result)
       //fetchUsers();
    }
    const getDataE =(e)=>{
        props.editRow(e)
    }
    const fetchUsers = async () => {
        const listUser = await axios.get(`https://nuggettrap.com/api/list/role-list.php`)
        setDataList(listUser.data.result)
        console.log(listUser);
    }

    React.useEffect(() => {
        fetchUsers();
    }, [])
    React.useEffect(()=>{
        console.log(props.dataAfterSubmit)
        setDataList(props.dataAfterSubmit)
    },[props.dataAfterSubmit])
    return (
        <TableContainer component={Paper}>
            <p></p>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>Role name </TableCell>
                        <TableCell align="right">Total coin</TableCell>
                        <TableCell align="right">Description</TableCell>
                        <TableCell align="right">Offet</TableCell>
                        <TableCell align="right">Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataList.length > 0 &&
                        <>
                            {dataList.map((list,i)=>(
                                <Row key={i} row={list} delrow={okcheck} editRow={getDataE}></Row>
                            ))}
                        </>
                    }
                    {dataList.length === 0 && 
                    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell colSpan={5} align='center'>
                        <h4>Data not found</h4>
                    </TableCell>
                    </TableRow>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}

//export default RoleLIst