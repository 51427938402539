import React from 'react'

import img1 from '../img/im1.png';
import img2 from '../img/im2.png';
import img3 from '../img/im3.png';
import img4 from '../img/im4.png';
import { Link } from 'react-router-dom';

import imga11 from '../img/img11.png';
import img12 from '../img/img12.png';
import img13 from '../img/img13.png';
import img14 from '../img/img14.png';

import bitmart from '../img/Bitmart Logo 4 White.png'
import Lbank from '../img/Lbank Logo 2.png'
import toobit from '../img/toobit.png';
import ascendex from '../img/ascendex.png'
import { Col, Row } from 'reactstrap';


function Cex() {
    return (
        <div>
            <div className='container'>
                <div className='client-galary pt-5'>
                    <h4 className='text-center text-a'>Now Listed On Major Crypto Exchanges (Cex)!</h4>
                    <div className='galary-inner pt-5'>

                        <div className='img-box'>
                            <Link to='https://www.biconomy.com/exchange/NGTG$$_USDT' target='_blank'>
                                <img src={img1} alt="" />
                            </Link>
                        </div>

                        <div className='img-box'>
                            <Link to='https://www.xt.com/en/trade/ngtg_usdt' target='_blank'>
                                <img src={img2} alt="" />
                            </Link>
                        </div>
                        <div className='img-box'>
                            <Link to='https://dex-trade.com/spot/trading/NGTG$$USDT?interface=classic' target='_blank'>
                                <img src={img3} alt="" />
                            </Link>
                        </div>
                        <div className='img-box'>
                            <Link to='https://uzx.com/#/exchange/ngtg_usdt' target='_blank'>
                                <img src={img4} alt="" />
                            </Link>
                        </div>
                        <div className='img-box'>
                            <Link to='https://ascendex.com/en/cashtrade-spottrading/usdt/ngtg' target='_blank'>
                                <img src={imga11} alt="" />
                            </Link>
                        </div>
                    </div>
                    <div className='galary-inner pt-5 pb-5'>
                        <div className='img-box'>
                            <Link to='https://www.toobit.com/en-US/spot/NGTG_USDT' target='_blank'>
                                <img src={img12} alt="" />
                            </Link>
                        </div>
                        <div className='img-box'>
                            <Link to='https://www.weex.com/trade/NGTG-USDT' target='_blank'>
                                <img src={img13} alt="" />
                            </Link>
                        </div>
                        <div className='img-box'>
                            <Link to='https://www.koinpark.com/trade/BTC-INR' target='_blank'>
                                <img src={img14} alt="" />
                            </Link>
                        </div>
                        <div className='img-box'>
                            <Link to='https://www.bitmart.com/trade/en-US?symbol=NGTG%24%24_USDT' target='_blank'>
                                <img src={bitmart} alt="" />
                            </Link>
                        </div>
                        <div className='img-box'>
                            <Link to='https://www.lbank.com/trade/ngtg_usdt' target='_blank'>
                                <img src={Lbank} alt="" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container pt-5'>
                <Row>
                    <Col sm={6}>
                        <Link to="https://www.toobit.com/en-US/spot/NGTG_USDT" target='_blank'>
                            <h3 className='text-center cca pb-1'>
                                <img src={img12} alt='' />
                            </h3>
                        </Link>
                        <Link to="https://www.toobit.com/en-US/spot/NGTG_USDT" target='_blank'>
                            <img src={toobit} alt='' className='width-100 hight-1' />
                        </Link>

                    </Col>
                    <Col sm={6}>
                        <Link to="https://ascendex.com/en/cashtrade-spottrading/usdt/ngtg" target='_blank'>
                            <h3 className='text-center cca pb-1'>
                                <img src={imga11} alt='' />
                            </h3>
                        </Link>
                        <Link to="https://ascendex.com/en/cashtrade-spottrading/usdt/ngtg" target='_blank'>
                            <img src={ascendex} alt='' className='width-100 hight-1' />
                        </Link>

                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Cex