import React, { useState, useEffect } from 'react'
import {
    Card, Breadcrumbs, Typography,  Button,
    Backdrop, IconButton,
    CircularProgress
    
} from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {FaTrash } from 'react-icons/fa'
import { AiOutlineFileAdd } from 'react-icons/ai';
import Snackbar from '@mui/material/Snackbar';


function BusinessList() {
    const navigate = useNavigate();
    const [sopen, setSOpen] = React.useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');


    const [usetList, setUserList] = useState([]);
    const handleClose = (event, reason
    ) => {
        if (reason === 'clickaway') {
            return;
        }

        setSOpen(false);
    };
    const fetchUsers = async () => {
        const listUser = await axios.get(`https://nuggettrap.com/api/list/business-list.php`)
        const nnData = listUser.data.result;
        console.log(nnData)
        setUserList(nnData)
    }
    const handalDel = async (row) => {
        if (window.confirm('Are you sure') === true) {
            setOpen(true)
            const saveBusinessData = await axios.post(`${process.env.REACT_APP_API_URL}/business/delete`, row)
            setOpen(false);
            setMessage('Delete success !');
            setSOpen(true);
            console.log(saveBusinessData)
            
            setTimeout(() => {
                fetchUsers();
            }, 1000);
        }
    }
    useEffect(() => {
        fetchUsers();
    }, [])
    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>List</Typography>
                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Button variant="contained" color='primary'
                    onClick={() => { navigate('/dashboard/business') }}
                >Add new
                    <AiOutlineFileAdd size={20} />
                </Button>
                <Card style={{ padding: '10px' }}>
                    <h4>Business list</h4>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell >Address of Principal Office</TableCell>
                                    <TableCell>Type of Organization</TableCell>
                                    <TableCell>Country</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell align='center'>Action </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {usetList.map((row, i) => (
                                    <TableRow
                                        key={i}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell > {row.datas.name}</TableCell>
                                        <TableCell >{row.datas.address_of_principal_office}</TableCell>
                                        <TableCell>{row.datas.type_org}</TableCell>
                                        <TableCell>{row.datas.country_orgaization}</TableCell>
                                        <TableCell>{row.create_date.slice(0, 10)}</TableCell>
                                        <TableCell >
                                            <IconButton color='error' onClick={() => { handalDel(row) }}>
                                                <FaTrash />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
                <Snackbar
                    open={sopen}
                    autoHideDuration={3000}
                    onClose={handleClose}
                    severity="success"
                    message={message}
                />
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </div>
    )
}

export default BusinessList