import React, { useEffect, useState } from 'react'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { FaDotCircle } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";

import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import axios from 'axios';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import Swal from 'sweetalert2'


function Poll() {
    const theme = useTheme();
    const [qusList, setQusList] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [maxSteps, setmaxSteps] = useState(0)
    const [newPools, setNewPools] = useState({fullname:'', email:'', contact:''})
    const [open,setOpen] = useState(false);


    const fetchList = async () => {
        const resist = await axios.get(`${process.env.REACT_APP_API_URL}/pool/list`)
        if (resist.data.error === 0) {
            for (let i = 0; i < resist.data.result.length; i++) {
                resist.data.result[i].selectedOpt = false;
            }
            setTimeout(() => {
                console.log(resist.data.result)
                setmaxSteps(resist.data.result.length)
                setQusList(resist.data.result);
            }, 100);
        }
        console.log(resist)
    }
    const toggleStatus = (opt, index) => {
        const newData = [...qusList];
        newData[index] = {
            ...newData[index],
            selectedOpt: opt
        };
        console.log(newData)
        setQusList(newData);
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handalSubmit = async() =>{
        if(newPools.fullname ===''){ return alert('Please enter your name.') }
        if(newPools.contact ===''){ return alert('Please enter contact number.') }
        if(newPools.email ===''){ return alert('Please enter email.') }
        var allData = newPools;
        allData.allQus = JSON.stringify(qusList);

      
        setOpen(true);
        const resAdd = await axios.post(`${process.env.REACT_APP_API_URL}/pool/submit`, allData)
        if(resAdd.data.error===0){
           // alert('Saved')
           Swal.fire({
            icon: "success",
            title: "Successfully saved",
            showConfirmButton: false,
            timer: 2000
          });
        }
        setOpen(false);
        setNewPools({fullname:'', email:'', contact:''})
        fetchList()
    }
    useEffect(() => {
        console.log(qusList)
        fetchList()
    }, [])

    return (
        <div>
            <h4 className='text-center'>Have Your Say in Our Poll!</h4>
            <p>
                Your opinion is vital to shaping the future of NuggetTrap. This quick poll is your chance to share your thoughts and influence how we evolve.
                Take a moment to participate and help us make NuggetTrap even better for the mining community
            </p>
            <div className='pt-5'>
                <Form>
                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>Full Name</Label>
                                <Input
                                    placeholder="Enter your fullname"
                                    type="text" value={newPools.fullname}
                                    onChange={(e)=>{ setNewPools((prev)=>({...prev,fullname:e.target.value })) }}
                                />
                            </FormGroup>
                            </Col>
                            <Col sm={6}>
                            <FormGroup>
                                <Label>Contact</Label>
                                <Input
                                    placeholder="Enter your contact number"
                                    type="text" value={newPools.contact}
                                    onChange={(e)=>{ setNewPools((prev)=>({...prev,contact:e.target.value })) }}
                                />
                            </FormGroup>
                            </Col><Col sm={6}>
                            <FormGroup>
                                <Label>Email</Label>
                                <Input
                                    placeholder="Enter your email"
                                    type="email" value={newPools.email}
                                    onChange={(e)=>{ setNewPools((prev)=>({...prev,email:e.target.value })) }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </div>
            <Box sx={{ width: '100%', p: 2 }}>

                {/* {JSON.stringify(qusList[activeStep]?.qus)} */}
                <div className='pt-5' >
                    <h4>{activeStep+1}.  {qusList[activeStep]?.qus}</h4>
                    <div className='list-data'>
                        <List
                            sx={{ width: '100%', bgcolor: 'background.paper' }}
                            aria-label="contacts"
                        >
                            <ListItem disablePadding className={`${qusList[activeStep]?.selectedOpt === qusList[activeStep]?.option1 ? 'active' : ''}`}>
                                <ListItemButton
                                    onClick={() => toggleStatus(qusList[activeStep]?.option1, activeStep)}>
                                    <ListItemIcon>
                                        {qusList[activeStep]?.selectedOpt === qusList[activeStep]?.option1 ? (<FaCheckCircle />) : (<FaDotCircle />)}
                                    </ListItemIcon>
                                    <ListItemText primary={qusList[activeStep]?.option1} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding className={`${qusList[activeStep]?.selectedOpt === qusList[activeStep]?.option2 ? 'active' : ''}`}>
                                <ListItemButton onClick={() => toggleStatus(qusList[activeStep]?.option2, activeStep)} >
                                    <ListItemIcon>
                                        {qusList[activeStep]?.selectedOpt === qusList[activeStep]?.option2 ? (<FaCheckCircle />) : (<FaDotCircle />)}
                                    </ListItemIcon>
                                    <ListItemText primary={qusList[activeStep]?.option2} />
                                </ListItemButton>
                            </ListItem>

                            <ListItem disablePadding className={`${qusList[activeStep]?.selectedOpt === qusList[activeStep]?.option3 ? 'active' : ''}`}>
                                <ListItemButton onClick={() => toggleStatus(qusList[activeStep]?.option3, activeStep)}>
                                    <ListItemIcon>
                                        {qusList[activeStep]?.selectedOpt === qusList[activeStep]?.option3 ? (<FaCheckCircle />) : (<FaDotCircle />)}
                                    </ListItemIcon>
                                    <ListItemText primary={qusList[activeStep]?.option3} />
                                </ListItemButton>
                            </ListItem>

                            <ListItem disablePadding className={`${qusList[activeStep]?.selectedOpt === qusList[activeStep]?.option4 ? 'active' : ''}`}>
                                <ListItemButton onClick={() => toggleStatus(qusList[activeStep]?.option4, activeStep)}>
                                    <ListItemIcon>
                                        {qusList[activeStep]?.selectedOpt === qusList[activeStep]?.option4 ? (<FaCheckCircle />) : (<FaDotCircle />)}
                                    </ListItemIcon>
                                    <ListItemText primary={qusList[activeStep]?.option4} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </div>
                </div>
            </Box>
            <MobileStepper
                variant="text"
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                    <Button
                        size="small"
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                    >
                        Next
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft />
                        ) : (
                            <KeyboardArrowRight />
                        )}
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowRight />
                        ) : (
                            <KeyboardArrowLeft />
                        )}
                        Back
                    </Button>
                }
            />
            <Row>
                <Col sm={6} pt={5} pb={5} className='offset-sm-3'>
                    <Button variant="contained" fullWidth onClick={handalSubmit}>Save</Button>
                </Col>
            </Row>
            {/* <PollQustions /> */}


        </div>
    )
}

export default Poll