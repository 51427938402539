import React from 'react'
//import NewsList from './NewsList'
import NewsList2 from './NewsList2'



function NewEvent() {
    return (
        <div>
            <div className='bg-one'></div>

            <div>
                <div className='container pt-5 pb-5 new-s'>
                    <h2>News </h2>
                    <NewsList2 />
                </div>
            </div>
        </div>
    )
}

export default NewEvent