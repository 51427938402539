import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Grid2, IconButton, InputAdornment, TextField, FormControl, InputLabel, Box, OutlinedInput, Button, Backdrop, CircularProgress } from '@mui/material'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import logo from '../img/logo.png'
// import OtpInput from 'react-otp-input';


function Login() {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [emailError, setemailError] = React.useState(false);

    const [getchekotp, setgetchekotp] = useState(true);
    const [signUp, setSignUp] = useState(false);

    const [otp] = useState('');
    const [serverotp, setServerOtp] = useState('');
    const [loginUser] = useState('admin');

    const handalGetOtp = async () => {
        if (user.userid) {
            const resposn = await axios.post(`${process.env.REACT_APP_API_URL}/user/otp`, user)
            if (resposn.data.error === 1) {
                alert("User doesn't exist!")
                setemailError(true);
            } else {
                setServerOtp(resposn.data.otp)
                setgetchekotp(false)
                setemailError(false);
            }
            console.log(resposn)

        }
    }
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleMouseUpPassword = (event) => {
        event.preventDefault();
    };
    const [user, setUser] = useState({ name: '', userid: '', password: '' });
    function onChange(value) {
        console.log("Captcha value:", value);
    }
    
    const goSignUp = async()=>{
        setOpen(true)
         await axios.post(`${process.env.REACT_APP_API_URL}/user/add`, user)
        setOpen(false);
        alert('User sucessfully saved !')
        setUser({ name: '', userid: '', password: '' })
    }
    const gologin = async () => {
        console.log(serverotp)
        console.log(otp)
        if (serverotp === otp) {

        } else {
            alert('Please enter valid OTP')
        }
        if (user.userid) {
            setOpen(true);
            const userData = await axios.post(`${process.env.REACT_APP_API_URL}/user/login`, user);
            if (userData.data.error === 0 && userData.data?.result?.id) {
                localStorage.setItem('user', JSON.stringify(userData.data.result))
                setTimeout(() => {
                    navigate('/dashboard')
                }, 500);
            } else {
                setOpen(false);
                alert('Please enter valid user id or password')
            }
        } else {
            alert('Please enter user id or password')
        }
    }
    useEffect(() => {

    }, [otp])

    return (
        <div>
            <Grid2 container >
                <Grid2 size={6}>
                    <div className='log-box'>
                        <div className='center-div'>
                            <img src={logo} alt='logo' className='login-img' />
                            {signUp === false &&
                            <h4 className='text-center'>Login</h4>
                            }
                            {signUp === true &&
                            <h4 className='text-center'>Sign Up</h4>
                            }

                            {/* <FormControl component="fieldset">
                                <RadioGroup row aria-label="gender" name="row-radio-buttons-group"
                                    onChange={(e) => { setLoginUser(e.target.value) }} value={loginUser}>
                                    <FormControlLabel value="admin" control={<Radio />} label="Admin" />
                                    <FormControlLabel value="user" control={<Radio />} label="User" />
                                </RadioGroup>
                            </FormControl> */}
                            <p className='text-center'>Enter your email below to login</p>

                            <Box className="box">
                                {signUp === true &&
                                    <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                        <TextField type='text' label="Name" variant="outlined" size='small'
                                            error={emailError}
                                            value={user.name} onChange={(e) => { setUser((prev) => ({ ...prev, name: e.target.value })) }} />
                                    </FormControl>
                                }

                                <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                    <TextField type='email' label="Email" variant="outlined" size='small'
                                        error={emailError}
                                        value={user.userid} onChange={(e) => { setUser((prev) => ({ ...prev, userid: e.target.value })) }} />
                                </FormControl>
                                {loginUser === 'admin' ? (
                                    <>
                                        <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-password"
                                                type={showPassword ? 'text' : 'password'}
                                                size='small'
                                                value={user.password} onChange={(e) => { setUser((prev) => ({ ...prev, password: e.target.value })) }}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            onMouseUp={handleMouseUpPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                label="Password"
                                            />
                                        </FormControl>
                                    </>
                                ) : (
                                    <>

                                    </>
                                )}
                                {getchekotp === false &&
                                    <FormControl sx={{ m: 1, }} variant="outlined">
                                        {/* <OtpInput
                                            value={otp}
                                            onChange={(e) => { handalOtp(e) }}
                                            numInputs={6}
                                            renderSeparator={<span>-</span>}
                                            renderInput={(props) => <input {...props} className='form-centrol otp' />}
                                        /> */}
                                    </FormControl>
                                }


                                <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                    <ReCAPTCHA
                                        sitekey="6LevsUUqAAAAALvisRg3xhlTL5bPcVysO6AIXHeu"
                                        onChange={onChange}
                                    />
                                </FormControl>

                                <p className='text-right'>
                                    <Link to="/">Forgot password ?</Link>
                                </p>

                                {loginUser === 'user' && getchekotp === true &&
                                    <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                        <Button variant="contained"
                                            onClick={handalGetOtp}>Get OTP</Button>
                                    </FormControl>
                                }
                                {signUp === false &&
                                    <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                        <Button variant="contained"
                                            onClick={gologin}>Login</Button>
                                    </FormControl>
                                }
                                {signUp === true &&
                                    <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                        <Button variant="contained"
                                            onClick={goSignUp}>Sign Up</Button>
                                    </FormControl>
                                }
                                {signUp === false &&
                                    <FormControl>
                                        <Button onClick={() => { setSignUp(true) }}>Sign Up</Button>
                                    </FormControl>
                                }

                                {signUp === true &&
                                    <FormControl>
                                        <Button onClick={() => { setSignUp(false) }}>Sign In</Button>
                                    </FormControl>
                                }
                            </Box>
                            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={open}>
                                <CircularProgress color="inherit" />
                            </Backdrop>
                        </div>
                    </div>
                </Grid2>
                <Grid2 size={6}>
                    <div className='login-bg'>

                    </div>
                </Grid2>
            </Grid2>
        </div >
    )
}

export default Login