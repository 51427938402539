import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumbs, Typography } from '@mui/material'
import { Button, Card, Col, Row } from 'reactstrap'
import { IoIosAddCircle } from "react-icons/io";
import axios from 'axios';

function Addform() {
    const [formData, setFormData] = useState({ type: '', fildName: '', labelName: '', options: [] });
    const [allform, setAllform] = useState([]);

    const [Option, setOneOption] = useState('');
    const [addOptions, setOption] = useState([]);
    const [showOptions, setShowOption] = useState(false)

    const handlAdd = async () => {
        if (formData.type === '') return alert('Please enter Input type!');
        if (formData.fildName === '') return alert('Please enter Input filed name!');
        if (formData.labelName === '') return alert('Please enter Input label name!');
        if (formData.type === 'input_radio' || formData.type === 'input_checkbox' || formData.type === 'input_select') {
            if (addOptions.length === 0) {
                return alert('Please enter Input label name!');
            }
        }
        if (addOptions.length > 0) {
            const faildData = formData;
            faildData.options = addOptions;
            setAllform([...allform, faildData])
            setOption([])
        } else {
            setAllform([...allform, formData])
        }
        setTimeout(() => {
            setFormData({ type: '', fildName: '', labelName: '', options: [] })
            setShowOption(false)
        }, 500);
    }
    function CustomInput({ type, options, filed }) {
        switch (type) {
            case 'input_text':
            case 'input_email':
            case 'input_number':
                return (
                    <div>
                        <input className='form-control'
                            type={type}

                        />
                    </div>
                );
            case 'input_file':
                return (
                    <div>
                        <input type="file" className='form-control' />
                    </div>
                );
            case 'input_select':
                return (
                    <div>
                        <select className='form-control'>
                            <option value=''>Select option</option>
                            {options &&
                                options.map((option, index) => (
                                    <option value={option} key={index}>
                                        {option}
                                    </option>
                                ))}
                        </select>
                    </div>
                );
            case 'input_radio':
                return (
                    <div>
                        {options &&
                            options.map((option, index) => (
                                <div key={index}>
                                    <input
                                        type="radio"
                                        value={option}
                                        name={filed}
                                    />
                                    <span>{option}</span>
                                </div>
                            ))}
                    </div>
                );
            case 'input_checkbox':
                return (
                    <div>

                        {options &&
                            options.map((option, index) => (
                                <div key={index}>
                                    <input
                                        type="checkbox"
                                        name={filed}
                                        value={option}

                                    />
                                    <span>{option}</span>
                                </div>
                            ))}
                    </div>
                );
            case 'input_textarea':
                return (
                    <div>

                        <textarea className='form-control'></textarea>
                    </div>
                );
            case 'input_button':
                return (
                    <div>
                        <Button color='primary'>Button</Button>
                    </div>
                );
            default:
                return null;
        }
    }
    const selectOptions = async (val) => {
        if (val === 'input_radio' || val === 'input_checkbox' || val === 'input_select') {
            setShowOption(true)
        }
        setFormData((prev) => ({ ...prev, type: val }))
    }
    const SaveForm = async () => {
        const addres = await axios.post(`${process.env.REACT_APP_API_URL}/form/add`, { formData: JSON.stringify(allform) })
        console.log(addres)
        if (addres.data.error === 0) {
            alert('Form successfully saved ')
        }
    }
    const fetchData = async () => {
        const dataRes = await axios.get(`${process.env.REACT_APP_API_URL}/form/list`)
        setAllform(dataRes.data.result)
        console.log(dataRes)
    }
    useEffect(() => {
        fetchData()
    }, [])
    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>List</Typography>
                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Card className='pt-5 pb-5 ps-5'>
                    <Row>
                        <Col sm={3}>
                            <div>
                                <label>Input field <span className='text-danger'>*</span> </label>
                                <select className='form-control' value={formData.type}
                                    onChange={(e) => (selectOptions(e.target.value))}
                                // onChange={(e) => { setFormData((prev) => ({ ...prev, type: e.target.value })) }}
                                >
                                    <option value=''>Select filed</option>
                                    <option value='input_text'>Input Text</option>
                                    <option value='input_email'>Input Email</option>
                                    <option value='input_number'>Input Number</option>
                                    <option value='input_select'>Input Select</option>
                                    <option value='input_file'>Input File</option>
                                    <option value='input_radio'>Input Radio</option>
                                    <option value='input_checkbox'>Input Checkbox</option>
                                    <option value='input_textarea'>Textarea</option>
                                    <option value='input_button'>Button</option>
                                </select>
                            </div>
                        </Col>
                        <Col sm={3}>
                            <div>
                                <label>Add lavel name <span className='text-danger'>*</span></label>
                                <input type='text' className='form-control'
                                    value={formData.labelName} onChange={(e) => { setFormData((prev) => ({ ...prev, labelName: e.target.value })) }} />
                            </div>
                        </Col>
                        <Col sm={3}>
                            <div>
                                <label>Add filed name <span className='text-danger'>*</span></label>
                                <input type='text' className='form-control'
                                    value={formData.fildName} onChange={(e) => { setFormData((prev) => ({ ...prev, fildName: e.target.value })) }} />
                            </div>
                        </Col>
                    </Row>
                    {showOptions &&
                        <Row>
                            <Col sm={3}>
                                <div>
                                    <label>Add Option</label>
                                    <div className='input-group'>
                                        <input type='text' className='form-control'
                                            value={Option}
                                            onChange={(e) => { setOneOption(e.target.value) }} />
                                        <Button color='success' onClick={() => {
                                            setOption([...addOptions, Option])
                                            setOneOption('')
                                        }}>Add</Button>
                                    </div>
                                </div>
                            </Col>
                            {addOptions.length > 0 &&
                                <>
                                    {addOptions.map((list, i) => (
                                        <Col sm={3} key={i}>
                                            <div>
                                                <label> Option</label>
                                                <div className='input-group'>
                                                    <input type='text' className='form-control' value={list} />
                                                    <Button color='danger'>Del</Button>
                                                </div>
                                            </div>
                                        </Col>
                                    ))}
                                </>
                            }
                        </Row>
                    }
                    <Row>
                        <Col sm={3}>
                            <div className='pt-3'>
                                <Button color='primary' block
                                    onClick={handlAdd}
                                >Add
                                    <IoIosAddCircle />
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Card>
                <Card className='mt-2 pt-5 pb-5 px-5'>
                    <div>
                        <Row>
                            <Col sm={6}>
                                {allform.length > 0 &&
                                    <>
                                        {allform.map((list, i) => (
                                            <div key={i} >
                                                <label>{list.labelName}</label>
                                                <CustomInput type={list.type} options={list.options} filed={list.fildName} />
                                                <div>
                                                    <Button color='danger' type='button'>Delete</Button>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} className='pt-5 pb-5 ps-5'>
                                <Button type='button' color='primary' onClick={SaveForm} block>Save</Button>
                            </Col>
                        </Row>
                    </div>
                </Card>
            </div>
        </div>
    )
}

export default Addform