import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumbs, Typography,Backdrop, CircularProgress } from '@mui/material'

import { Button, Card, Col, Row } from 'reactstrap'
import { IoIosAddCircle } from "react-icons/io";
import axios from 'axios';


function AddPolls() {
    const [newPools, setNewPools] = useState({ category: '', qus: '', option1: '', option2: '', option3: '', option4: '' })
    const [open, setOpen] = useState(false);

    const handalSubmit = async()=>{
        if(newPools.qus ===''){ return alert('Please enter Question!') }
        if(newPools.option1 ===''){ return alert('Please select Option1!') }
        setOpen(true)
        const resAdd = await axios.post(`${process.env.REACT_APP_API_URL}/pool/add`, newPools)
        setOpen(false)
        console.log(resAdd)
        setNewPools({ category: '1', qus: '', option1: '', option2: '', option3: '', option4: '' })
    }
    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>List</Typography>
                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Card className='px-5 py-5'>
                    <Row>
                        {/* <Col sm={4}>
                            <div>
                                <label>Select Category <span className='text-danger'>*</span> </label>
                                <select className='form-control' value={newPools.category}
                                onChange={(e)=>{ setNewPools((prev)=>({...prev,category:e.target.value })) }}>
                                    <option>Select option</option>
                                    <option value='General Awareness Polls'>General Awareness Polls</option>
                                    <option value="Community Engagement Polls">Community Engagement Polls</option>
                                    <option value="Fun Polls">Fun Polls</option>
                                </select>
                            </div>
                        </Col> */}
                        <Col sm={8}>
                            <div>
                                <label>Question <span className='text-danger'>*</span> </label>
                                <input type='text' className='form-control' value={newPools.qus}
                                onChange={(e)=>{ setNewPools((prev)=>({...prev,qus:e.target.value })) }} />
                            </div>
                        </Col>
                        </Row>
                        <Row>
                        <Col sm={3}>
                            <div>
                                <label>Option 1 <span className='text-danger'>*</span> </label>
                                <input type='text' className='form-control' value={newPools.option1}
                                onChange={(e)=>{ setNewPools((prev)=>({...prev,option1:e.target.value })) }}/>
                            </div>
                        </Col>
                        <Col sm={3}>
                            <div>
                                <label>Option 2 <span className='text-danger'>*</span> </label>
                                <input type='text' className='form-control' value={newPools.option2}
                                onChange={(e)=>{ setNewPools((prev)=>({...prev,option2:e.target.value })) }}/>
                            </div>
                        </Col>
                        <Col sm={3}>
                            <div>
                                <label>Option 3  </label>
                                <input type='text' className='form-control' value={newPools.option3}
                                onChange={(e)=>{ setNewPools((prev)=>({...prev,option3:e.target.value })) }}/>
                            </div>
                        </Col>
                        <Col sm={3}>
                            <div>
                                <label>Option 4 </label>
                                <input type='text' className='form-control' value={newPools.option4}
                                onChange={(e)=>{ setNewPools((prev)=>({...prev,option4:e.target.value })) }}/>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <div>
                                <label> </label>
                                <Button color='primary' block onClick={handalSubmit}>Add</Button>
                            </div>
                        </Col>
                    </Row>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Card>
            </div>
        </div>
    )
}

export default AddPolls