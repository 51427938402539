import React from 'react'
import { Link } from 'react-router-dom';
import { Breadcrumbs, Card } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { AiFillRobot } from 'react-icons/ai';
import { MdSubscriptions } from "react-icons/md";
import { FcInvite } from "react-icons/fc";



function Home() {
    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>

                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Card style={{ padding: '10px', height: '300px' }}>
                    <div className='dashboard-ul'>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <AiFillRobot />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Role" secondary="Total role" />
                            <ListItemText id="switch-list-label-wifi" primary="6" />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <MdSubscriptions />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary=" Subscription Master" secondary="Total Subscription" />
                            <ListItemText id="switch-list-label-wifi" primary="3" />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <FcInvite />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Invite" secondary="Total Invite" />
                            <ListItemText id="switch-list-label-wifi" primary="4" />
                        </ListItem>
                    </div>

                    
                </Card>
            </div>
        </div>
    )
}

export default Home