import React from 'react'
import { Button, Col, Row } from 'reactstrap'
import img from '../img/gold.png'
import photo from '../img/photo.png'
import photo1 from '../img/photo1.png'
// import Pagination from '@mui/material/Pagination';
// import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom'
import { AiOutlineArrowRight } from "react-icons/ai";
function NewsList2() {
    return (
        <div>
            <Row className='pt-2 pb-2 news-a'>
                <Col sm={4}>
                    <div>
                        <Link to='https://finance.yahoo.com/news/american-creek-resources-receives-signing-113000615.html'>
                            <img src={img} alt="" style={{ width: '100%' }} />
                            <h4>American Creek Resources Receives Signing Fee Payment from Cunningham Mining</h4>
                            <p><i>Oct 8th, 2024</i></p>
                        </Link>
                        <p>
                            Cardston, Alberta--(Newsfile Corp. - October 2, 2024) - American Creek Resources Ltd. (TSXV: AMK) (OTCQB: ACKRF) (the "Company" or "American Creek") announces that it has received the $300,000 cash signing fee payment from Cunningham Mining Ltd. ("CML"), as required by the definitive arrangement agreement dated September 5, 2024 between the Company and CML, the details of which are set out in the Company's news release of September 6, 2024.
                        </p>
                        <div>
                            <Button color="primary"
                                href="https://finance.yahoo.com/news/american-creek-resources-receives-signing-113000615.html" tag="a"
                            >
                                Read more...
                                <AiOutlineArrowRight />
                            </Button>
                        </div>
                    </div>
                </Col>

                <Col sm={4}>
                    <div>
                        <Link to='https://www.globenewswire.com/news-release/2024/09/04/2940760/0/en/GEM-Digital-Limited-Increases-Investment-Commitment-With-Cunningham-Mining-Ltd-From-153M-USD-to-336M-USD.html'>
                            <img src={img} alt="" style={{ width: '100%' }} />
                            <h4>GEM Digital Limited Increases Investment Commitment With Cunningham Mining Ltd From $153M USD to $336M USD</h4>
                            <p><i>September 4th, 2024 </i> </p>
                        </Link>
                        <p>
                            VANCOUVER, British Columbia, Sept. 04, 2024 (GLOBE NEWSWIRE) -- Cunningham Mining Ltd is pleased to announce that the originally executed token subscription facility with GEM Digital Limited (“GEM Digital”) for the amount of $153 million USD has been increased to $336 Million USD, boasting major confidence to markets from the original financing facility that will be used for the proposed acquisition of American Creek Resources Ltd (TSXV: AMK). Cunningham Mining Ltd plans to use the increased facility for further acquisitions of gold properties in the coming future.
                        </p>
                        <div>
                            <Button color="primary"
                                href="https://www.globenewswire.com/news-release/2024/09/04/2940760/0/en/GEM-Digital-Limited-Increases-Investment-Commitment-With-Cunningham-Mining-Ltd-From-153M-USD-to-336M-USD.html" tag="a"
                            >
                                Read more...
                                <AiOutlineArrowRight />
                            </Button>
                        </div>
                    </div>
                </Col>

                <Col sm={4}>
                    <div>
                        <Link to='https://www.globenewswire.com/news-release/2024/08/05/2924685/0/en/Cunningham-Mining-Ltd-Secures-153-Million-USD-Investment-Commitment-from-GEM-Digital-Limited-for-American-Creek-Resources-Ltd-Acquisition-with-RWA-Token.html'>
                            <img src={img} alt="" style={{ width: '100%' }} />
                            <h4>American Creek Announces LOI Exclusivity Period Extension</h4>
                            <p><i>August 06, 2024</i> </p>
                        </Link>
                        <p>
                            VANCOUVER, British Columbia, Aug. 05, 2024 (GLOBE NEWSWIRE) -- Cunningham Mining Ltd is pleased to announce that it has executed a token subscription facility with GEM Digital Limited (“GEM Digital”) for the amount of $153 million USD. The funding from this financing facility will be used specifically for the proposed acquisition of American Creek Resources Ltd, a resources company listed on the TSX-V exchange.
                        </p>
                        <div>
                            <Button color="primary"
                                href="https://www.globenewswire.com/news-release/2024/08/05/2924685/0/en/Cunningham-Mining-Ltd-Secures-153-Million-USD-Investment-Commitment-from-GEM-Digital-Limited-for-American-Creek-Resources-Ltd-Acquisition-with-RWA-Token.html" tag="a"
                            >
                                Read more...
                                <AiOutlineArrowRight />
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className='pt-2 pb-2 news-a'>
                <Col sm={4}>
                    <div>
                        <Link to='https://www.newsfilecorp.com/release/218973/American-Creek-Announces-LOI-Exclusivity-Period-Extension'>
                            <img src={photo1} alt="" style={{ width: '100%' }} />
                            <h4>Cunningham Mining Ltd Secures $153 Million USD Investment Commitment from GEM Digital Limited for American Creek Resources Ltd Acquisition with RWA Token</h4>
                            <p><i>August 05, 2024 </i> </p>
                        </Link>
                        <p>
                            Cardston, Alberta--(Newsfile Corp. - August 6, 2024) - American Creek Resources Ltd. (TSXV: AMK) (OTCQB: ACKRF) (the "Company" or "American Creek") provides an update relating to its news release of June 6, 2024, in which it announced that it has entered into a non-binding and arm's length letter of intent ("LOI") with Cunningham Mining Ltd. ("Cunningham"), whereby Cunningham,
                        </p>
                        <div>
                            <Button color="primary"
                                href="https://www.newsfilecorp.com/release/218973/American-Creek-Announces-LOI-Exclusivity-Period-Extension" tag="a"
                            >
                                Read more...
                                <AiOutlineArrowRight />
                            </Button>
                        </div>
                    </div>
                </Col>

                <Col sm={4}>
                    <div>
                        <Link to='https://www.platinumcryptoacademy.com/press-release/cunningham-is-transforming-mining-metals-into-a-digital-assets '>
                            <img src={photo1} alt="" style={{ width: '100%' }} />
                            <h4>Cunningham Mining Ltd Announces The Transforming Of “old School Mining And Precious Metals Sector” Into A Thriving Digital Asset Economy Providing Global Reach.</h4>
                            <p><i>July 30th, 2024</i> </p>
                        </Link>
                        <p>
                            Vancouver, BC: Cunningham Mining Ltd, a leading Mining and Precious Metals company in Canada, is proud to announce its innovative approach to transforming the old school mining sector into a thriving digital asset economy. By leveraging digital assets and appealing to the “Digital Crypto Economy”, Cunningham Mining Ltd is poised to revolutionize the financing and global reach of the Mining and Precious Metals sector.
                        </p>
                        <div>
                            <Button color="primary"
                                href="https://www.platinumcryptoacademy.com/press-release/cunningham-is-transforming-mining-metals-into-a-digital-assets" tag="a"
                            >
                                Read more...
                                <AiOutlineArrowRight />
                            </Button>
                        </div>
                    </div>
                </Col>

                <Col sm={4}>
                    <div>
                        <Link to='https://finance.yahoo.com/news/cunningham-mining-updates-ngtgold-tokenization-200057352.html?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cubGlua2VkaW4uY29tLw&guce_referrer_sig=AQAAALPwVq41YL0DFsK7YZ82GOBXr5YOy4rg1MKqoBCiM_H1hS3zioVsUYyfppNqTvCyG65d7ZDFBgX_CVmn6ox6sIABb2D65YByfW8DmEWsZUvNoQuktx8FMyv1pirCkC5OCHDZiUOvrbUzP88PI0ZskkEWwQ8jnGQcuWOa5vgoiAj3'>
                            <img src={photo1} alt="" style={{ width: '100%' }} />
                            <h4>Cunningham Mining Updates NGTGOLD Tokenization Structure With Pending First Ever Purchase of a Public Company</h4>
                            <p><i>July 17th, 2024</i> </p>
                        </Link>
                        <p>
                            VANCOUVER, BC - (NewMediaWire) - July 16, 2024 - Cunningham Mining Ltd provided the crypto community more details on certain aspects of its "Nugget Trap Gold" RWA Token, which aims to transform the antiquated Mining Sector, as well as other details concerning the overall tokenization structure. The company is actively engaged in diligence required for further asset additions into the Nugget Trap Gold token.
                        </p>
                        <div>
                            <Button color="primary"
                                href="https://finance.yahoo.com/news/cunningham-mining-updates-ngtgold-tokenization-200057352.html?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cubGlua2VkaW4uY29tLw&guce_referrer_sig=AQAAALPwVq41YL0DFsK7YZ82GOBXr5YOy4rg1MKqoBCiM_H1hS3zioVsUYyfppNqTvCyG65d7ZDFBgX_CVmn6ox6sIABb2D65YByfW8DmEWsZUvNoQuktx8FMyv1pirCkC5OCHDZiUOvrbUzP88PI0ZskkEWwQ8jnGQcuWOa5vgoiAj3" tag="a"
                            >
                                Read more...
                                <AiOutlineArrowRight />
                            </Button>
                        </div>
                    </div>
                </Col>
                </Row>
                <Row className='pt-2 pb-2 news-a'>
                <Col sm={4}>
                    <div>
                        <Link to='https://www.newsfilecorp.com/release/211974/American-Creek-Announces-NonBinding-Letter-of-Intent-and-Exclusivity-with-Cunningham-Mining-Regarding-Proposed-Plan-of-Arrangement '>
                            <img src={photo1} alt="" style={{ width: '100%' }} />
                            <h4>American Creek Announces Non-Binding Letter of Intent and Exclusivity with Cunningham Mining Regarding Proposed Plan of Arrangement</h4>
                            <p><i>June 06, 2024</i> </p>
                        </Link>
                        <p>
                            Cardston, Alberta--(Newsfile Corp. - June 6, 2024) - American Creek Resources Ltd. (TSXV: AMK) ("American Creek" or the "Company"), announces that it has entered into a non-binding and arm's length letter of intent dated June 5, 2024 (the "LOI") with Cunningham Mining Ltd. ("Cunningham"), a private British Columbia corporation, to engage in due diligence and negotiations regarding the terms of a proposed transaction (the "Proposed Transaction") whereby Cunningham, or a subsidiary of Cunningham, would acquire all of the issued and outstanding securities of the Company.
                        </p>
                        <div>
                            <Button color="primary"
                                href="https://www.newsfilecorp.com/release/211974/American-Creek-Announces-NonBinding-Letter-of-Intent-and-Exclusivity-with-Cunningham-Mining-Regarding-Proposed-Plan-of-Arrangement" tag="a"
                            >
                                Read more...
                                <AiOutlineArrowRight />
                            </Button>
                        </div>
                    </div>
                </Col>

                <Col sm={4}>
                    <div>
                        <Link to='https://www.prnewswire.com/news-releases/cunningham-mining-updates-kharroubba-copper-transaction-302144292.html'>
                            <img src={photo1} alt="" style={{ width: '100%' }} />
                            <h4>
                                Cunningham Mining Updates Kharroubba Copper Transaction
                            </h4>
                            <p><i>May 14, 2024</i> </p>
                        </Link>
                        <p>
                            VANCOUVER, BC, May 14, 2024 /PRNewswire/ -- Cunnigham Mining Ltd, a minority interest portfolio company of Houston Natural Resources Corp (OTC: HNRC) ("Company"), provided an update on acquiring an interest of up to 19.8% in Kharrouba Copper Company, Inc ("KCC" or "Company"). Cunningham Mining Ltd will be assuming the financing commitment into KCC.
                        </p>
                        <div>
                            <Button color="primary"
                                href="https://www.prnewswire.com/news-releases/cunningham-mining-updates-kharroubba-copper-transaction-302144292.html" tag="a"
                            >
                                Read more...
                                <AiOutlineArrowRight />
                            </Button>
                        </div>
                    </div>
                </Col>
                
                <Col sm={4}>
                    <div>
                        <Link to='https://www.prnewswire.com/news-releases/hnrc-ventures-into-the-digital-economy-302061423.html'>
                        <img src={photo1} alt="" style={{ width: '100%' }} />
                            <h4>
                                HNRC Ventures into the Digital Economy
                            </h4>
                            <p>
                                <i>Feb 14, 2024 </i>
                            </p>
                        </Link>
                        <p>
                            HOUSTON, Feb. 14, 2024 /PRNewswire/ -- Houston Natural Resources Corp (OTC: HNRC) ("HNRC or the Company") is thrilled to announce a groundbreaking milestone in its strategic journey towards the digital economy. HNRC has successfully endorsed the transformation of its 9% holding in Cunningham Mining, located in Canada's prestigious BC Golden Triangle, into a pioneering Digital Asset Token. Information on the Digital Asset Token is at  (bit.ly/3SFgDBm).
                        </p>
                        <div>
                            <Button color="primary"
                                href="https://www.prnewswire.com/news-releases/hnrc-ventures-into-the-digital-economy-302061423.html" tag="a"
                            >
                                Read more...
                                <AiOutlineArrowRight />
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
            {/* <Row className='pt-5 pb-5'>
                            <Col sm={6} className='text-center offset-sm-5'>
                                <Stack spacing={2}>
                                    <Pagination count={1} color="primary" />
                                </Stack></Col>
                        </Row> */}
        </div>
    )
}

export default NewsList2