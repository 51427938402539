import { Card, TextField, Breadcrumbs, Typography, FormControl, Autocomplete, Button } from '@mui/material'
import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import { AiOutlineUserAdd } from 'react-icons/ai'


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import MuiAlert from '@mui/material/Alert';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const typecurrency = ['days', 'months', 'years'];

function TimePeriod() {
    const [timeperiod, settimeperiod] = useState({});
    const [ timepList, setTimePlist ] = useState([]);

    const [sopen, setSOpen] = React.useState(false);
    const [eopen, setEOpen] = React.useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');

    const handleClose = (event, reason
    ) => {
        if (reason === 'clickaway') {
            return;
        }
        setSOpen(false);
    };
    const handleClose2 = (event, reason
    ) => {
        if (reason === 'clickaway') {
            return;
        }
        setEOpen(false);
    };
    const handalDel = async(data)=>{
        setOpen(true)
         await axios.post(`${process.env.REACT_APP_API_URL}/time-period/delete`, data)
        setOpen(false)
        setMessage('Delete Success');
        setSOpen(true);
        gteAll();
    }

    const handalSubmit = async () => {
        console.log(timeperiod)
        if (!timeperiod.num || timeperiod.num === '') { return alert('Please enter numer of Timeperiod') }
        if (!timeperiod._type || timeperiod._type === '') { return alert('Please enter Timeperiod type') }
        setOpen(true)
        const resAdd = await axios.post(`${process.env.REACT_APP_API_URL}/time-period/add`, timeperiod)
        setOpen(false)
        if (resAdd.data.error === 1) {
            // errror
            setMessage('Something was wrong !')
            setEOpen(true);
        } else {
            // success
            setMessage('Time period successfully added !')
            setSOpen(true);
            settimeperiod({ num: '', _type: '' })
            gteAll();
        }
    }
    const gteAll = async()=>{
        const resAdd = await axios.get(`https://nuggettrap.com/api/list/time-p.php`)
        setTimePlist(resAdd.data.result);
        console.log(resAdd.data.result)
    }
    useEffect(()=>{
        gteAll();
    },[])

    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>Time period</Typography>
                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Card style={{ padding: '10px' }}>
                    <h4>Time period</h4>
                    <div className="row">
                        <div className='col-sm-6' >
                            <form>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Enter period" size='small'
                                        variant="outlined" type='number'
                                        InputLabelProps={{ shrink: true }}
                                        value={timeperiod.num}
                                        onChange={(e) => { settimeperiod((prev) => ({ ...prev, num: e.target.value })) }}
                                    />
                                </FormControl>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <Autocomplete
                                        size='small'
                                        options={typecurrency}
                                        sx={{ width: 230 }}
                                        value={timeperiod._type}
                                        onChange={(e, v) => { settimeperiod((prev) => ({ ...prev, _type: v })) }}
                                        renderInput={(params) => <TextField {...params} label="Currency type" />}
                                    />
                                </FormControl>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <Button variant="contained" style={{ width: '100%' }}
                                        onClick={handalSubmit}>Create
                                        <AiOutlineUserAdd />
                                    </Button>
                                </FormControl>
                            </form>
                        </div>
                        <div className='col-sm-6'>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 450 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>SL</TableCell>
                                            <TableCell>Number</TableCell>
                                            <TableCell >Type</TableCell>
                                            <TableCell >Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {timepList.map((row,i) => (
                                            <TableRow
                                                key={row.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell >{i+1}</TableCell>
                                                <TableCell >{row.num}</TableCell>
                                                <TableCell>{row._type}</TableCell>
                                                <TableCell > 
                                                <Button variant="contained" color='error'
                                                onClick={()=>{ handalDel(row) }}
                                                >Delete</Button>

                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </Card>
                <Snackbar
                    open={sopen}
                    autoHideDuration={3000}
                    onClose={handleClose}
                    severity="success"
                    message={message}
                >
                    <Alert severity="success">{message} </Alert>
                </Snackbar>

                <Snackbar
                    open={eopen}
                    autoHideDuration={3000}
                    onClose={handleClose2}
                    message={message}
                >
                    <Alert severity="error">{message}</Alert>
                </Snackbar>

                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>

        </div>
    )
}

export default TimePeriod