import React, { useState } from 'react'
import { Form, Row, Col, FormGroup, Label, Input, Button } from 'reactstrap'
import { IoSend } from "react-icons/io5";
import axios from 'axios';
import Swal from 'sweetalert2'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function ComunnityFeedBack() {
    const [feedBack, setFeedback] = useState('');
    const [title, setTitle] = useState('');

    const [open, setOpen] = useState(false);
    const handalSave = async () => {
        if (feedBack === '') { return alert('Please enter feedback!') }
        if (title === '') { return alert('Please enter feedback Subject!') }
        setOpen(true);
        const resSave = await axios.post(`${process.env.REACT_APP_API_URL}/feedback/add`, { feedback: feedBack, title: title })
        setOpen(false);
        setFeedback('');
        Swal.fire({
            icon: "success",
            title: "Successfully send!",
            showConfirmButton: false,
            timer: 2000
        });
    }
    return (
        <div>
            <Form>
                <div className='py-5 px-5'>
                    <h2 className='text-center pb-2'>We Value Your Feedback!</h2>
                    <p>
                        Your thoughts, ideas, and experiences are essential in helping us improve and grow.
                        At Nugget Trap, we're committed to building a better platform and
                        ommunity for everyone. Whether you have suggestions, questions, or insights
                        about our utility token NGTG$$ or the mining industry's digital transformation,
                        we'd love to hear from you!
                    </p>
                </div>
                <Row className='bg-white'>
                    <Col md={6} className='offset-md-3'>
                        <h4>Feedback</h4>
                        <FormGroup>
                            <Label>Subject</Label>
                            <Input
                                name="text"
                                type="text" value={title}
                                onChange={(e) => { setTitle(e.target.value) }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Answer</Label>
                            <Input
                                name="text"
                                type="textarea" value={feedBack}
                                onChange={(e) => { setFeedback(e.target.value) }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Button color='primary' block className='fw-bold'
                                onClick={handalSave}
                            >Send  {" "}
                                <IoSend />
                            </Button>
                        </FormGroup>
                    </Col>
                </Row>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Form>
        </div>
    )
}

export default ComunnityFeedBack