import React, { useState,useEffect } from 'react'

import { Card, Col, Row, Table, Button } from 'reactstrap'
import { Breadcrumbs, Typography } from '@mui/material'
import { Link } from 'react-router-dom';
import { FaTrash } from 'react-icons/fa';
import axios from 'axios';

function TokenRegistorList() {
    const [questionList, setquestionList] = useState([]);
    const [serverUrl] = useState('https://nuggettrap.com/api/');

    const fetchQus = async () => {
        const resaa = await axios.get(`${serverUrl}welcome-list.php`);
        setquestionList(resaa.data.result)
        console.log(resaa.data.result)
    }
    const deleteF = async (data) => {
        if (window.confirm("Are you sure!") == true) {
            const resaa = await axios.post(`${serverUrl}welcome-delete.php?id=${data.id}`);
            fetchQus();
        }
    }
    useEffect(() => {
        fetchQus();
    }, [])
    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>List</Typography>
                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Card className='pt-5 pb-5 ps-5'>
                    <Row className='bg-white width-100'>
                        <Col md={12} className=''>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Sl</th>
                                        <th>Full name</th>
                                        <th>Email</th>
                                        <th>Contact</th>
                                        <th>Date of birth</th>
                                        <th>Country</th>
                                        <th>Amount</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {questionList.length > 0 &&
                                        <>
                                            {questionList.map((list, i) => (
                                                <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td>{list.title} {list.f_name} {list.l_name} </td>
                                                    <td>{list.email} </td>
                                                    <td>{list.mobile} </td>
                                                    <td>{list.dob} </td>
                                                    <td>{list.country} </td>
                                                    <td>{list.amount} </td>
                                                    <td>
                                                    <Button size='sm' color='danger' onClick={() => { deleteF(list) }} ><FaTrash /> </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Card>

            </div>
        </div>
    )
}

export default TokenRegistorList