//import './App.css';
import './style.css'
import { Route, Routes } from 'react-router-dom';
import Home from './component/Home';
import Login from './component/Login';

import Dashboard from './admin/Dashboard'
import LoginAd from './component/LoginAd';

function App() {
  return (
    <div >
        <Routes>
          <Route path='/' element={<Home />} ></Route>
          <Route path='/home/*' element={<Home />} ></Route>
          <Route path='/login' element={<Login />} ></Route>
          <Route path='/admin-login' element={<LoginAd />} ></Route>
          <Route path='/dashboard/*' element={<Dashboard />} ></Route>
        </Routes>


    </div>
  );
}

export default App;
