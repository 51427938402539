import React, { useEffect, useState } from 'react'
import {
    Card, Breadcrumbs, Typography,  Button, Divider, ButtonGroup, IconButton
} from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import { AiOutlineFileAdd } from 'react-icons/ai'

import axios from 'axios'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { FaEdit, FaTrash } from 'react-icons/fa'



function SubscriptionMasterList() {
    const navigate = useNavigate();

    const [usetList, setUserList] = useState([]);
    const fetchUsers = async () => {
        const listUser = await axios.get(`https://nuggettrap.com/api/list/scrubpition-list.php`)
        const nnData = listUser.data.result;
        console.log(nnData)
        setUserList(nnData)
        //console.log(listUser.data.result);
    }
    const handalDelete = async (row) => {
        if (window.confirm('Are you sure!') === true) {

            await axios.post(`${process.env.REACT_APP_API_URL}/subscription/delete`, row)
            //const nnData = listUser.data.result;
            setTimeout(() => {
                fetchUsers()
            }, 1000);
        }
    }
    useEffect(() => {
        fetchUsers();
    }, [])

    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>List</Typography>
                </Breadcrumbs>
            </div>

            <div className='text-inner'>
                <Button variant="contained" color='primary'
                    onClick={() => { navigate('/dashboard/subscription-master') }}
                >Add new
                    <AiOutlineFileAdd size={20} />
                </Button>
                <Card style={{ padding: '10px' }}>
                    <h3 className='text-center'>Subscription Master List</h3>
                    <Divider />
                    <hr />
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>First name</TableCell>
                                    <TableCell >Last name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Company name</TableCell>
                                    <TableCell>Contact person </TableCell>
                                    <TableCell> Investment Amount </TableCell>
                                    <TableCell> Price per Coin($) </TableCell>
                                    <TableCell> Total Coin </TableCell>
                                    <TableCell>Action </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {usetList.map((row, i) => (
                                    <TableRow
                                        key={i}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell > {row.fname}</TableCell>
                                        <TableCell >{row.lname}</TableCell>
                                        <TableCell>{row.email}</TableCell>
                                        <TableCell>{row.date_}</TableCell>
                                        <TableCell>{row.type}</TableCell>
                                        <TableCell>{row.company_name}</TableCell>
                                        <TableCell >{row.contact_person}</TableCell>
                                        <TableCell >{row.invstamt === 'undefined' ? 'na' : row.invstamt}</TableCell>
                                        <TableCell >{row.price_coin === 'undefined' ? 'na' : row.price_coin}</TableCell>
                                        <TableCell >{row.total_coin ? row.total_coin : 'na'}</TableCell>
                                        <TableCell >
                                            <ButtonGroup size="small" aria-label="Small button group">

                                                <IconButton color='error' onClick={() => { handalDelete(row) }}>
                                                    <FaTrash />
                                                </IconButton>
                                                <IconButton color="warning"
                                                    onClick={() => { navigate('/dashboard/subscription-master', { state: row }) }}>
                                                    <FaEdit />
                                                </IconButton>
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            </div>
        </div>
    )
}

export default SubscriptionMasterList