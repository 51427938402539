import React, { useEffect, useState } from 'react'
import {
    Card,  TextField, Breadcrumbs, Typography, FormControl, Button,
    RadioGroup, FormControlLabel, Radio,  InputAdornment, Backdrop, Autocomplete,
    CircularProgress
} from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import { AiOutlineOrderedList } from 'react-icons/ai'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CountrySelect from './CountrySelect';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';

function Business(props) {
    const navigate = useNavigate();

    const [open, setOpen] = React.useState(false);
    const [sopen, setSOpen] = React.useState(false);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);


    const typecurrency = ['Monthly', 'Annual'];
    const handleClose = (event, reason
    ) => {
        if (reason === 'clickaway') {
            return;
        }

        setSOpen(false);
    };

    const [businessData, setBusinesData] = useState({});

    const getContry = (e) => {
        setBusinesData((prev) => ({ ...prev, indv_country: e }))
    }
    const getContry2 = (e) => {
        setBusinesData((prev) => ({ ...prev, partner_country: e }))
    }
    const handalSubmit = async () => {
        if (businessData.name) {
            setOpen(true)
            const saveBusinessData = await axios.post(`${process.env.REACT_APP_API_URL}/business/add`, businessData)
            setOpen(false)
            setSOpen(true);
            setBusinesData({})
            console.log(saveBusinessData)
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        } else {
            alert('Please enter name')
        }
    }
    const getAllCountry = async () => {
        const listUser = await axios.get(`https://nuggettrap.com/api/ekyc/country-list.php`)
        setCountryList(listUser.data.result)
        // console.log(listUser.data.result)
    }
    const handalGetState = async (id) => {
        const stateList = await axios.get(`https://nuggettrap.com/api/ekyc/state-list.php?countryid=${id}`)
        setStateList(stateList.data.result)
    }
    useEffect(() => {
        getAllCountry();
    }, [])
    return (
        <div>
            {!props?.checka === 1 &&
                <div className='box'>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href="/">
                            Dashboard
                        </Link>
                        <Typography sx={{ color: 'text.primary' }}>List</Typography>
                    </Breadcrumbs>
                </div>
            }
            <div className='text-inner'>
            {!props?.checka ===1 &&
                <Button variant="contained" color='primary'
                    onClick={() => { navigate('/dashboard/business-list') }}
                >List
                    <AiOutlineOrderedList size={20} />
                </Button> }

                <Card style={{ padding: '10px' }}>
                    <h4>Business</h4>
                    <form>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Name of Entity" size='small'
                                value={businessData.name} onChange={(e) => { setBusinesData((prev) => ({ ...prev, name: e.target.value })) }}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Address of Principal Office" size='small'
                                value={businessData.address_of_principal_office} onChange={(e) => { setBusinesData((prev) => ({ ...prev, address_of_principal_office: e.target.value })) }}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Type of Organization" size='small'
                                value={businessData.type_org} onChange={(e) => { setBusinesData((prev) => ({ ...prev, type_org: e.target.value })) }}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            {/* <TextField
                                label="State of Organization" size='small'
                                value={businessData.state_orgaization} onChange={(e) => { setBusinesData((prev) => ({ ...prev, state_orgaization: e.target.value })) }}
                            /> */}
                            <Autocomplete
                                disablePortal
                                size='small'
                                options={countryList}
                                onChange={(e, val) => {
                                    setBusinesData((prev) => ({ ...prev, country_orgaization: val?.name }))
                                    handalGetState(val?.id)
                                }}
                                sx={{ width: 230 }}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <TextField {...params} label="Country" />}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <Autocomplete
                                disablePortal
                                size='small'
                                options={stateList}
                                onChange={(e, val) => {
                                    setBusinesData((prev) => ({ ...prev, state_orgaization: val?.name }))
                                }}
                                sx={{ width: 230 }}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <TextField {...params} label="State" />}
                            />
                        </FormControl>
                        <hr />
                        <h6>Business</h6>

                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Major Segments of Operation" size='small'
                                value={businessData.major_segments_opration} onChange={(e) => { setBusinesData((prev) => ({ ...prev, major_segments_opration: e.target.value })) }}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Length of operation" size='small'
                                value={businessData.length_operation} onChange={(e) => { setBusinesData((prev) => ({ ...prev, length_operation: e.target.value })) }}
                            />
                        </FormControl>
                        <p>Is the entity a reporting entity under the U.S. Securities Exchange Act of 1934, as amended?</p>
                        <FormControl component="fieldset">
                            <RadioGroup row aria-label="gender" name="row-radio-buttons-group"
                                value={businessData.securities_exchange_act_1934} onChange={(e) => { setBusinesData((prev) => ({ ...prev, securities_exchange_act_1934: e.target.value })) }}
                            >
                                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                <FormControlLabel value="no" control={<Radio />} label="No" />
                            </RadioGroup>
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <p>The names and business experience of each of the entity's officers and directors, partners, or
                                other control persons for the past five years</p>
                            <TextField
                                label="Enter text"
                                size='small' variant="standard" style={{ width: '100%' }}
                                value={businessData.the_names_bunisess_experience} onChange={(e) => { setBusinesData((prev) => ({ ...prev, the_names_bunisess_experience: e.target.value })) }}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <p>The educational background of each of the entity's officers and directors, partners, or other
                                control persons, including the institutions attended, the dates of attendance, and the degrees obtained
                                by each. If additional space is required to answer any question, please attach separate pages to the
                                back of this Questionnaire and identify all questions answered in this fashion by their respective
                                question numbers</p>
                            <TextField
                                label="Enter text"
                                size='small' variant="standard" style={{ width: '100%' }}
                                value={businessData.the_education_background_each_entity} onChange={(e) => { setBusinesData((prev) => ({ ...prev, the_education_background_each_entity: e.target.value })) }}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <p>Each of the entity's control persons who are natural persons, if any, must complete Part A of this
                                Questionnaire. Please attach these additional pages to the back of this Questionnaire</p>
                            <TextField
                                label="Enter text"
                                size='small' variant="standard" style={{ width: '100%' }}
                                value={businessData.each_entity_control_perons} onChange={(e) => { setBusinesData((prev) => ({ ...prev, each_entity_control_perons: e.target.value })) }}
                            />
                        </FormControl>
                        <hr />
                        <h6>Current Investment Objectives</h6>

                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <Autocomplete
                                disablePortal
                                size='small'
                                options={typecurrency}
                                sx={{ width: 230 }}
                                onChange={(event, newval) => {
                                    setBusinesData((prev) => ({ ...prev, _type: newval }));

                                }}
                                renderInput={(params) => <TextField {...params} label="Type" />}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Current income" size='small'
                                value={businessData.current_investment_objects} onChange={(e) => { setBusinesData((prev) => ({ ...prev, current_investment_objects: e.target.value })) }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Appreciation" size='small'
                                value={businessData.appreciation} onChange={(e) => { setBusinesData((prev) => ({ ...prev, appreciation: e.target.value })) }}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Tax Shelter" size='small'
                                value={businessData.tax_shelter} onChange={(e) => { setBusinesData((prev) => ({ ...prev, tax_shelter: e.target.value })) }}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Other" size='small'
                                value={businessData.other} onChange={(e) => { setBusinesData((prev) => ({ ...prev, other: e.target.value })) }}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <p>
                                Please describe any additional information that reflects the entity's knowledge and experience in
                                business, financial, or investment matters and the entity's ability to evaluate the merits and risks of this
                                investment. If additional space is required to answer any question, please attach separate pages to the back of
                                this Questionnaire and identify all questions answered in this fashion by their respective question numbers
                            </p>
                            <TextField
                                label="Enter text"
                                size='small' variant="standard" style={{ width: '100%' }}
                                value={businessData.describe_additional_information} onChange={(e) => { setBusinesData((prev) => ({ ...prev, describe_additional_information: e.target.value })) }}
                            />
                        </FormControl>
                        <h4>Accredited Investor Status </h4>

                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <p>
                                <TextField
                                    label="Enter text"
                                    size='small' variant="standard"
                                    value={businessData.a_broker_dealer_registered} onChange={(e) => { setBusinesData((prev) => ({ ...prev, a_broker_dealer_registered: e.target.value })) }}
                                />
                                A broker or dealer registered under Section 15 of the U.S. Securities
                                Exchange Act of 1934, as amended.
                            </p>
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <p>
                                <TextField
                                    label="Enter text"
                                    size='small' variant="standard"
                                    value={businessData.an_investment_adviser_registered} onChange={(e) => { setBusinesData((prev) => ({ ...prev, an_investment_adviser_registered: e.target.value })) }}
                                />
                                An investment adviser registered pursuant to Section 203 of the U.S.
                                Investment Advisers Act of 1940, as amended, or registered pursuant to the laws of a U.S. state
                            </p>
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <p>
                                <TextField
                                    label="Enter text"
                                    size='small' variant="standard"
                                    value={businessData.an_investment_adviser_relying} onChange={(e) => { setBusinesData((prev) => ({ ...prev, an_investment_adviser_relying: e.target.value })) }}
                                />
                                An investment adviser relying on the exemption from registering with the
                                U.S. Securities and Exchange Commission under Section 203(l) or (m) of the U.S. Investment Advisers Act
                                of 1940, as amended.
                            </p>
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <p>
                                <TextField
                                    label="Enter text"
                                    size='small' variant="standard"
                                    value={businessData.an_insurance_company_defined} onChange={(e) => { setBusinesData((prev) => ({ ...prev, an_insurance_company_defined: e.target.value })) }}
                                />
                                An insurance company, as defined in Section 2(a)(13) of the U.S.
                                Securities Act.
                            </p>
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <p>
                                <TextField
                                    label="Enter text"
                                    size='small' variant="standard"
                                    value={businessData.an_investment_company_registered_under_us} onChange={(e) => { setBusinesData((prev) => ({ ...prev, an_investment_company_registered_under_us: e.target.value })) }}
                                />
                                An investment company registered under the U.S. Investment Company
                                Act of 1940, as amended, or a business development company, as defined in Section 2(a)(48) of that Act.
                            </p>
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <p>
                                <TextField
                                    label="Enter text"
                                    size='small' variant="standard"
                                    value={businessData.a_small_business_investment_company_licesed} onChange={(e) => { setBusinesData((prev) => ({ ...prev, a_small_business_investment_company_licesed: e.target.value })) }}
                                />
                                A Small Business Investment Company licensed by the U.S. Small
                                Business Administration under Section 301(c) or (d) of the U.S. Small Business Investment Act of 1958.
                            </p>
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <p>
                                <TextField
                                    label="Enter text"
                                    size='small' variant="standard"
                                    value={businessData.a_rule_business_investment_company} onChange={(e) => { setBusinesData((prev) => ({ ...prev, a_rule_business_investment_company: e.target.value })) }}
                                />
                                A Rural Business Investment Company as defined in Section 384A of the
                                U.S. Consolidated Farm and Rural Development Act.
                            </p>
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <p>
                                <TextField
                                    label="Enter text"
                                    size='small' variant="standard"
                                    value={businessData.a_plan_established_and_mantained_state} onChange={(e) => { setBusinesData((prev) => ({ ...prev, a_plan_established_and_mantained_state: e.target.value })) }}
                                />
                                A plan established and maintained by a state, its political subdivisions or
                                any agency or instrumentality of a state or its political subdivisions for the benefit of its employees, if the
                                plan has total assets in excess of US$5 million
                            </p>
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <p>
                                <TextField
                                    label="Enter text"
                                    size='small' variant="standard"
                                    value={businessData.an_employee_benefit_plan_within_meaning} onChange={(e) => { setBusinesData((prev) => ({ ...prev, an_employee_benefit_plan_within_meaning: e.target.value })) }}
                                />
                                An employee benefit plan within the meaning of Title I of the U.S.
                                Employee Retirement Income Security Act of 1974, as amended, if the investment decision is being made by
                                a plan fiduciary, as defined in Section 3(21) of such Act, and the plan fiduciary is either a bank, a savings
                                and loan association, an insurance company, or a registered investment adviser, or if the employee benefit
                                plan has total assets in excess of US$5 million, or if the employee benefit plan is a self-directed plan in
                                which investment decisions are made solely by persons that are accredited investors
                            </p>
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <p>
                                <TextField
                                    label="Enter text"
                                    size='small' variant="standard"
                                    value={businessData.a_private_business_development_company} onChange={(e) => { setBusinesData((prev) => ({ ...prev, a_private_business_development_company: e.target.value })) }}
                                />
                                A private business development company, as defined in Section 202(a)
                                (22) of the U.S. Investment Advisers Act of 1940, as amended.
                            </p>
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <p>
                                <TextField
                                    label="Enter text"
                                    size='small' variant="standard"
                                    value={businessData.a_corporation_massachusetts_similar} onChange={(e) => { setBusinesData((prev) => ({ ...prev, a_corporation_massachusetts_similar: e.target.value })) }}
                                />
                                A corporation, Massachusetts or similar business trust, partnership, or
                                limited liability company or an organization described in Section 501(c)(3) of the U.S. Internal Revenue
                                Code of 1986, as amended, that was not formed for the specific purpose of acquiring the Tokens, and that
                                has total assets in excess of US$5 million.
                            </p>
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <p>
                                <TextField
                                    label="Enter text"
                                    size='small' variant="standard"
                                    value={businessData.a_trust_total_assets_excess_us} onChange={(e) => { setBusinesData((prev) => ({ ...prev, a_trust_total_assets_excess_us: e.target.value })) }}
                                />
                                A trust with total assets in excess of US$5 million not formed for the
                                specific purpose of acquiring the Tokens, whose purchase is directed by a sophisticated person as described
                                in Rule 506(b)(2)(ii) under the U.S. Securities Act.
                            </p>
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <p>
                                <TextField
                                    label="Enter text"
                                    size='small' variant="standard"
                                    value={businessData.an_entity_which_all_equity_owners} onChange={(e) => { setBusinesData((prev) => ({ ...prev, an_entity_which_all_equity_owners: e.target.value })) }}
                                />
                                An entity in which all of the equity owners (whether entities themselves
                                or natural persons) are Accredited Investors and meet the criteria listed in either this Part B or in Part A of
                                this Questionnaire. Please also see "Additional Questions for Certain Accredited Investors" below.
                            </p>
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <p>
                                <TextField
                                    label="Enter text"
                                    size='small' variant="standard"
                                    value={businessData.an_entity_type_not_listed_in_clauses} onChange={(e) => { setBusinesData((prev) => ({ ...prev, an_entity_type_not_listed_in_clauses: e.target.value })) }}
                                />
                                An entity of a type not listed in clauses (a) through (n) above, that is not
                                formed for the specific purpose of acquiring the Tokens and owns investments in excess of US$5 million.
                                For purposes of this clause, "investments" means investments as defined in Rule 2a51-1(b) under the U.S.
                                Investment Company Act of 1940, as amended.
                            </p>
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <p>
                                <TextField
                                    label="Enter text"
                                    size='small' variant="standard"
                                    value={businessData.a_fimily_office_defined_in_rule_202} onChange={(e) => { setBusinesData((prev) => ({ ...prev, a_fimily_office_defined_in_rule_202: e.target.value })) }}
                                />
                                A family office, as defined in Rule 202(a)(11)(G)-1 under the U.S.
                                Investment Advisers Act of 1940, as amended, that (i) has assets under management in excess of US$5
                                million; (ii) is not formed for the specific purpose of acquiring the Tokens and (iii) has a person directing the
                                prospective investment who has such knowledge and experience in financial and business matters so that the
                                family office is capable of evaluating the merits and risks of the prospective investment.
                            </p>
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <p>
                                <TextField
                                    label="Enter text"
                                    size='small' variant="standard"
                                    value={businessData.a_family_clinet_defined_in_rule_202} onChange={(e) => { setBusinesData((prev) => ({ ...prev, a_family_clinet_defined_in_rule_202: e.target.value })) }}
                                />
                                A family client, as defined in Rule 202(a)(11)(G)-1 under the U.S.
                                Investment Advisers Act of 1940, as amended, of a family office meeting the requirements of clause (p)
                                above and whose prospective investment in the Tokens is directed by that family office pursuant to clause (p)
                            </p>
                        </FormControl>
                        <hr />
                        <h4>Individual</h4>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Individual name" size='small'
                                value={businessData.individual_name} onChange={(e) => { setBusinesData((prev) => ({ ...prev, individual_name: e.target.value })) }}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField type='file'
                                label="Signature" size='small'
                                InputLabelProps={{ shrink: true, required: true }}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <LocalizationProvider dateAdapter={AdapterDayjs} size='small'>
                                <DatePicker size='small'
                                    label="Date"
                                />
                            </LocalizationProvider>
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="State" size='small'
                                value={businessData.invd_state} onChange={(e) => { setBusinesData((prev) => ({ ...prev, invd_state: e.target.value })) }}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <CountrySelect selectedCountr={getContry} />
                        </FormControl>
                        <hr />
                        <h4>Partnership, Corporation or Other Entity</h4>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Name" size='small'
                                value={businessData.partnership_name} onChange={(e) => { setBusinesData((prev) => ({ ...prev, partnership_name: e.target.value })) }}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField type='file'
                                label="Signature" size='small'
                                InputLabelProps={{ shrink: true, required: true }}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <LocalizationProvider dateAdapter={AdapterDayjs} size='small'>
                                <DatePicker size='small'
                                    label="Date"
                                />
                            </LocalizationProvider>
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="State" size='small'
                                value={businessData.partnership_state} onChange={(e) => { setBusinesData((prev) => ({ ...prev, partnership_state: e.target.value })) }}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <CountrySelect selectedCountr={getContry2} />
                        </FormControl>

                        <br />
                        <br />

                        <Button variant="contained" style={{ width: '100%' }}
                            onClick={handalSubmit}>Submit</Button>
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={open}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        <Snackbar
                            open={sopen}
                            autoHideDuration={5000}
                            onClose={handleClose}
                            severity="success"
                            message="Business successfully added!"
                        />
                    </form>
                </Card>
            </div>
        </div>
    )
}

export default Business