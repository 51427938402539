import React, { useState, useEffect } from 'react'
import {
    Card,Breadcrumbs, Typography, Button, IconButton,ButtonGroup
} from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { FaTrash } from 'react-icons/fa'
import { AiOutlineFileAdd } from 'react-icons/ai';
import { IoCheckmarkDone } from "react-icons/io5";
import { RiMailCloseLine, RiMailCheckLine, RiMailCheckFill, RiMailCloseFill } from "react-icons/ri";
import Snackbar from '@mui/material/Snackbar';

function InviteList() {
    const navigate = useNavigate();
    const [usetList, setUserList] = useState([]);
    const [sopen, setSOpen] = React.useState(false);

    const handleClose = (event, reason
    ) => {
        if (reason === 'clickaway') {
            return;
        }

        setSOpen(false);
    };

    const fetchUsers = async () => {
        const listUser = await axios.get(`https://nuggettrap.com/api/list/invite-list.php`)
        const nnData = listUser.data.result;
        console.log(nnData)
        setUserList(nnData)
    }
    const handalDel = async (row) => {
        if (window.confirm('Are you sure!') === true) {
             await axios.post(`${process.env.REACT_APP_API_URL}/invite/delete`, row)
            setTimeout(() => {
                fetchUsers();
            }, 300);
        }
    }
    const handalState = async (status, row) => {
        row.status = status;
         await axios.post(`${process.env.REACT_APP_API_URL}/invite/update`, row)
        setSOpen(true);
        fetchUsers();
    }
    useEffect(() => {
        fetchUsers();
    }, [])
    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>List</Typography>
                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Button variant="contained" color='primary'
                    onClick={() => { navigate('/dashboard/invite') }}
                >Add new
                    <AiOutlineFileAdd size={20} />
                </Button>
                <Card style={{ padding: '10px' }}>
                    <h4>Invite list</h4>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell >Description</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Role</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell align='center'>Action </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {usetList.map((row, i) => (
                                    <TableRow
                                        key={i}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell > {row.name}</TableCell>
                                        <TableCell >{row.description}</TableCell>
                                        <TableCell>{row.email}</TableCell>
                                        <TableCell>{row.role}</TableCell>
                                        <TableCell>{row.create_date.slice(0, 10)}</TableCell>
                                        <TableCell >
                                        
                                            <ButtonGroup>
                                                <IconButton color='error' onClick={() => { handalDel(row) }}>
                                                    <FaTrash />
                                                </IconButton>
                                                {/*<IconButton color='warning' onClick={() => { navigate('/dashboard/invite', { state: row }) }}>
                                                    <FaEdit />
                                                </IconButton> */}
                                                {/* <IconButton >
                                                    <IoCheckmarkDone />
                                                </IconButton> */}
                                                
                                                {row.seen_status === '1' ? (
                                                    <IconButton color='success'>
                                                        <IoCheckmarkDone />
                                                    </IconButton>
                                                ) : (
                                                    <IconButton >
                                                        <IoCheckmarkDone />
                                                    </IconButton>
                                                )}
                                                
                                                {row.status === '0' &&
                                                    <>
                                                        <IconButton color='success' onClick={() => { handalState(1, row) }}>
                                                            <RiMailCheckLine />
                                                        </IconButton>
                                                        <IconButton color='error' onClick={() => { handalState(2, row) }}>
                                                            <RiMailCloseLine />
                                                        </IconButton>
                                                    </>
                                                }
                                                {row.status === '1' &&
                                                    <IconButton color='success' >
                                                        <RiMailCheckFill />
                                                    </IconButton>
                                                }
                                                {row.status === '2' &&
                                                    <IconButton color='error'>
                                                        <RiMailCloseFill />
                                                    </IconButton>
                                                }
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
                <Snackbar
                    open={sopen}
                    autoHideDuration={5000}
                    onClose={handleClose}
                    severity="success"
                    message="Invite successfully added!"
                />
            </div>
        </div>
    )
}

export default InviteList