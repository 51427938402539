import React from 'react'
import { Col, Row } from 'reactstrap'
//import img from '../img/gold.png'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import evntPhoto from '../img/event.jpeg'

import img1 from '../img/event/1.jpeg';
import img2 from '../img/event/2.jpg'
import img3 from '../img/event/3.jpg'
import img4 from '../img/event/4.jpeg'
import img5 from '../img/event/img3.jpeg'
import img6 from '../img/event/photo11.jpg'
import { XEmbed } from 'react-social-media-embed';


function EventPage() {
    return (
        <div>
            <div className='bg-one'></div>

            <div>
                <div className='container pt-5 pb-5 new-s'>
                    <div>
                        <Row className='pt-5 pb-5'>
                            <Col sm={6} >
                                <h5 className='text-center'> AMA recording</h5>
                                <XEmbed url="https://x.com/BiconomyCom/status/1853059926362407151?t=cCBhjPUiMUfW-Gd2LYV0JA&s=35" />
                            </Col>
                            {/* <Col sm={6} >
                                <h5>Nascar May 2024</h5>
                                <img src={img5} alt='' className='width-100' />
                            </Col> */}
                        </Row>

                        <Row className='pt-2 pb-2'>
                            <Col sm={8}>
                                <h5>Frankie Muniz & NuggetTrap NGTG$$   #CraftsmnaTruckSeries</h5>
                                <iframe width="100%" height="315" src="https://www.youtube.com/embed/3EW4SfKG17o?si=ThXU3D7zFSOEuKl9" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

                                <br /><br /> <br />
                                <h5>Cunningham Mining Nugget Trap at Nascar 2024 - It's Go time at Bristol</h5>
                                <iframe width="100%"  height="315" src="https://www.youtube.com/embed/x7uOLKyz7Mc?si=QS_548kA9GYrE2Mx" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                <br /><br /> <br />
                                <h5>Nascar Richmond Event August 2024</h5>
                                <iframe width="100%" height="315" src="https://www.youtube.com/embed/Lc2hNzjBkQw?si=DAoTZLSuS9swTyOK" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </Col>
                            <Col sm={4}>
                                <h5>Nascar Images & Videos</h5>
                                <div className='img-1'>
                                    <img src={img1} alt='' className='width-100' />
                                </div>
                                <div className='img-1'>
                                    <img src={img2} alt='' className='width-100' />
                                </div>
                                <div className='img-1'>
                                    <img src={img3} alt='' className='width-100' />
                                </div>
                                <div className='img-1'>
                                    <img src={img4} alt='' className='width-100' />
                                </div>
                                <div className='img-1'>
                                    <img src={img5} alt='' className='width-100' />
                                </div>
                            </Col>
                        </Row>
                        

                    </div>
                </div>
            </div>
        </div>
    )
}

export default EventPage